<template>
  <div class="mt-3">
    <template v-if="userAppAllResultAverage.length">
      <!-- <h5 class="pb-2"> App Results </h5> -->
      <div class="iq-card-body pt-0 w-100 p-0">
        <div class="leaderBoardList row m-0 p-0 pb-3">
          <h5 class="mb-2 primary-color">Scores Across Categories</h5>
          <li class="list-group-item1 mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 w-100 justify-content-between align-items-center" v-for="(app, index) of userAppAllResultAverage" :key="index+'appResults'">
            <template>
              <small> {{ app.qbk_category }}: {{(Number(app.count_answer_result_1)/Number(app.total_answers) * 100).toFixed(2) }} %</small>
          </template>
          <!-- <span class="badge badge-primary badge-pill">{{item.aprs_percentile}}</span> -->
          </li>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { AppResults } from "../FackApi/api/appResults"
export default {
  name: "AppResultLeaderBoard",
  props: {
    propUserId: {
      default: null
    },
    propType: {
      default: null
    }
  },
  components: {
  },
  data: () => ({
    userAppAllResultAverage: []
  }),
  mounted () {
    if (this.propUserId) {
      this.getUserAppAllResultAverage()
    }
  },
  methods: {
    /**
     * getUserAppAllResultAverage
    */
    async getUserAppAllResultAverage () {
      let apiResp = null
      if (this.propType == "APP_CATEGORY") {
        apiResp = await AppResults.appResultAllAverage(this, { current_user_id: this.propUserId })
      }
      else {
        apiResp = await AppResults.appResultAllCategoryAverage(this, { current_user_id: this.propUserId })
      }
      if (apiResp.resp_status) {
        this.userAppAllResultAverage = apiResp.resp_data.data
      }
    }
  }
}
</script>
